"use client";

interface DropdownItemProps {
  children: React.ReactNode;
  [x: string]: any;
}

function DropdownItem({ children, ...rest }: DropdownItemProps) {
  const handleOnClick = () => (document?.activeElement as HTMLElement)?.blur();
  return (
    <li onClick={handleOnClick} {...rest}>
      {children}
    </li>
  );
}

export default DropdownItem;
