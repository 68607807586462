"use client";

import classNames from "classnames";
import { usePathname } from "next/navigation";
import React from "react";
import ListItem from "@/components/Header/NavigationItem";
import Link from "@/components/Link";
import { NavigationItem } from "@/lib/types";

export type NavigationListProps = {
  navigationItems: NavigationItem[];
  className?: string;
  mobile?: boolean;
};

const NavigationList: React.FC<NavigationListProps> = ({
  navigationItems,
  mobile,
  className = "menu-horizontal px-1",
}) => {
  const path = usePathname();
  const ref = React.useRef(null);
  const handleClick = () => {
    const detailElement = ref.current as any;
    if (detailElement) {
      // remove open attribute from detailElement
      // @ts-ignore
      detailElement?.removeAttribute("open");
    }
  };

  return (
    <ul className={classNames("menu", className)} tabIndex={0}>
      {navigationItems?.map((navigationItem, index) => {
        const active = path.includes(navigationItem?.link!);
        return (
          <li className="cursor-pointer" key={`${navigationItem.name}_${index}`}>
            {navigationItem?.items?.length && !mobile && (
              <details ref={ref}>
                <summary className={classNames(active && "gradient-bottom-border font-bold")}>
                  {navigationItem.name}
                </summary>
                <ul className="bg-base-100 rounded-t-none">
                  {navigationItem?.items.map((item, childIndex) => (
                    <li key={`${navigationItem.name}_${index}_${childIndex}`}>
                      <ListItem name={item.name} link={item.link} action={handleClick} />
                    </li>
                  ))}
                </ul>
              </details>
            )}
            {navigationItem?.items?.length && mobile && (
              <>
                <a className={classNames(active && "gradient-bottom-border font-bold")}>{navigationItem.name}</a>
                <ul className="p-2">
                  {navigationItem?.items.map((item, childIndex) => (
                    <li key={`${navigationItem.name}_${index}_${childIndex}`}>
                      <Link href={item.link}>{item.name}</Link>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {!navigationItem?.items?.length && (
              <ListItem
                key={navigationItem.name}
                name={navigationItem.name}
                link={navigationItem.link}
                active={active}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default NavigationList;
