import classNames from "classnames";
import React from "react";
import Link from "@/components/Link";
import { NavigationItem } from "@/lib/types";

const ListItem = ({ name, link, className, active, action }: NavigationItem) => {
  return (
    <Link
      href={link}
      onClick={action}
      className={classNames("relative flex ", active && "gradient-bottom-border font-bold", className)}
    >
      {name}
    </Link>
  );
};

export default ListItem;
